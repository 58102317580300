<template>
  <LoadingBox
    v-if="$root.loading"
    transparent
    class="loading-box">
    <img src="./assets/loader-white.png" alt="loading" class="animated" />
  </LoadingBox>
  <transition  name="fade" v-else>
    <router-view></router-view>
  </transition>
</template>

<script>
import LoadingBox from 'q-custom-elements/src/components/elements/loader/LoadingBox'

export default {
  name: 'app',
  components: {
    LoadingBox
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
