<template>
  <SwiperJs ref="swiper"
    class="accounts-login-operation"
    :swipeable="false"
    :allowSlideNext="false"
    :allowSlidePrev="false">


<!-- check_browser -->
    <AccountsStepOperationStep :hidden="activeStep !== 'check_browser'"
      remote-step >

        <AccountsCard
          loading
          :error="getStepError('check_browser')"
          :description="gettext('Checking browser..')"
        />
      
    </AccountsStepOperationStep>


<!-- fetch_backend_stats -->
    <AccountsStepOperationStep :hidden="activeStep !== 'fetch_backend_stats'"
        :show-next-button="getStepError('fetch_backend_stats') != null"
        @nextButtonPressed="restartOperation"
        :next-button-text="gettext('Reload')"
        remote-step>

        <AccountsCard
          :loading="getStepError('fetch_backend_stats') == null"
          :error="getStepError('fetch_backend_stats')"
          :description="gettext('Fetching backend stats..')"
        />

    </AccountsStepOperationStep>


<!-- fetch_user_object -->
    <AccountsStepOperationStep :hidden="activeStep !== 'fetch_user_object'"
      remote-step >

        <AccountsCard
          loading
          :error="getStepError('fetch_user_object')"
          :description="gettext('Fetching user object..')"
        />

    </AccountsStepOperationStep>


<!-- instructions  -->
    <AccountsStepOperationStep :hidden="activeStep !== 'instructions'"
        first-step
        @nextButtonPressed="startOperation">

        <AccountsCard
          hide-media>

            <div class="instructions">
                <h2>{{gettext('Instructions')}}</h2>

                <h3>1. {{gettext('Insert username or e-mail')}}</h3>
                <h3>2. {{gettext('Insert password')}}</h3>
            </div>

        </AccountsCard>

    </AccountsStepOperationStep>


<!-- insert-credentials -->
    <AccountsStepOperationStep :hidden="activeStep !== 'insert-credentials'"
        :backButtonText="gettext('help')"
        @backButtonPressed="activateStep('instructions')"
        @nextButtonPressed="stepCompleted('insert-credentials', userCredentials)">

        <AccountsCard
          :description="credentialsMessage"
          :error="firstStepError"
          :hide-media="firstStepError != null"
        >

          <AccountsMdcTextField
            name="login"
            placeholder="Username / E-mail"
            ref="userCredentialsInput"
            v-model="userCredentials"
            :tabindex="activeStep === 'insert-credentials' ? 0 : -1"
            @submitted="stepCompleted('insert-credentials', userCredentials)"
          ></AccountsMdcTextField>

        </AccountsCard>

    </AccountsStepOperationStep>


<!-- check_credentials -->
    <AccountsStepOperationStep :hidden="activeStep !== 'check_credentials'"
        remote-step >

        <AccountsCard
          loading
          :description="gettext('Checking credentials..')"
        >
        </AccountsCard>

    </AccountsStepOperationStep>


<!-- insert-password -->
    <AccountsStepOperationStep :hidden="activeStep !== 'insert-password'"
        @nextButtonPressed="stepCompleted('insert-password', userPassword)"
        @backButtonPressed="goToStep('insert-credentials')">

        <AccountsCard
          :description="gettext('Insert password')"
          :error="getStepError('check_password')"
          hide-media
        >
          <AccountsMdcTextField
            name="password"
            type="password"
            placeholder="Password"
            ref="userPasswordInput"
            v-model="userPassword"
            :tabindex="activeStep === 'insert-password' ? 0 : -1"
            @submitted="stepCompleted('insert-password', userPassword)"
          ></AccountsMdcTextField>
        </AccountsCard>
    </AccountsStepOperationStep>


<!-- run_tasks -->
    <AccountsStepOperationStep :hidden="activeStep !== 'run_tasks'"
        remote-step>

        <AccountsCard
          loading
          :description="gettext('Logging in..')"
        >
        </AccountsCard>

    </AccountsStepOperationStep>


<!-- passepartout_redirects -->
    <AccountsStepOperationStep :hidden="activeStep !== 'passepartout_redirects'"
        remote-step>

        <AccountsCard
          :description="gettext('Enabling services..')"
        >
        </AccountsCard>
    </AccountsStepOperationStep>


<!-- check_user -->
    <AccountsStepOperationStep :hidden="activeStep !== 'check_user'"
        remote-step>

        <AccountsCard
          loading
          :error="getStepError('check_user')"
          :description="gettext('Checking user..')"
        />

    </AccountsStepOperationStep>


<!-- fetch_services -->
    <AccountsStepOperationStep :hidden="activeStep !== 'fetch_services'"
        remote-step>

        <AccountsCard
          loading
          :error="getStepError('fetch_services')"
          :description="gettext('Fetching services..')"
        />

    </AccountsStepOperationStep>


<!-- choose-service -->
    <AccountsStepOperationStep :hidden="activeStep !== 'choose-service'"
        hide-back-button
        :next-button-text="gettext('Profile')"
        @nextButtonPressed="goToProfile"
      >

        <AccountsCard
          hide-media
          >

          <h2>{{gettext('Choose a service')}}</h2>

          <AccountsServicesList
            v-if="services != null"
            :services="services"
            @serviceSelected="serviceSelected"
          />

        </AccountsCard>

    </AccountsStepOperationStep>


<!-- accept-tos -->
    <AccountsStepOperationStep :hidden="activeStep !== 'accept-tos'"
        @backButtonPressed="activateStep('choose-service')"
        @nextButtonPressed="stepCompleted('accept-tos', true)"
        :next-button-text="gettext('Accept')"
      >

        <AccountsCard
          hide-media
          :description="gettext('Click ACCEPT to accept terms of service')"
          >

            <div class="tos" v-html="termsOfService"></div>

        </AccountsCard>

    </AccountsStepOperationStep>


<!-- subscribe_service -->
    <AccountsStepOperationStep :hidden="activeStep !== 'subscribe_service'"
        remote-step>

        <AccountsCard
          loading
          :description="gettext('Subscribing service..')"
          :error="getStepError('subscribe_service')"
        />

    </AccountsStepOperationStep>


<!-- send_events -->
    <AccountsStepOperationStep :hidden="activeStep !== 'send_events'"
        remote-step>

        <AccountsCard
          loading
          :description="gettext('Sending events..')"
        >
        </AccountsCard>
    </AccountsStepOperationStep>


<!--  result -->
    <AccountsStepOperationStep :hidden="activeStep !== 'result'"
        remote-step>

        <AccountsCard
          loading
          :description="gettext('Redirecting..')"
        >
        </AccountsCard>
      
    </AccountsStepOperationStep>



  </SwiperJs>
</template>

<script>
import Modernizr from 'modernizr'

import GettextMixin from '@paiuolo/pai-vue-gettext/mixins/GettextMixin'
import LoadingElementMixin from '@paiuolo/pai-vue-mixins/mixins/LoadingElementMixin'

import FetchBackendStatsMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/FetchBackendStatsMixin'
import ApiLoginMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/ApiLoginMixin'
import ApiServiceSubscribeMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/ApiServiceSubscribeMixin'
import CheckUserExistenceMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/CheckUserExistenceMixin'
import NextUrlMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/NextUrlMixin'
import BrowserFingerprintMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/BrowserFingerprintMixin'
import FetchServicesMixin from '@paiuolo/django-sso-app-vue-mixins/mixins/FetchServicesMixin'

import SwiperJs from '@paiuolo/pai-vue-swiper/elements/swiper/SwiperJs'
import StepOperationSwiperJsMixin from '@paiuolo/pai-vue-swiper/mixins/StepOperationSwiperJsMixin'

import AccountsStepOperationStep from 'q-custom-elements/src/components/elements/layout/step-operations/accounts/AccountsStepOperationStep'
import AccountsMdcTextField from 'q-custom-elements/src/components/elements/input/accounts/AccountsMdcTextField'

import AccountsCard from 'q-custom-elements/src/components/elements/layout/step-operations/accounts/AccountsCard'
import AccountsServicesList from 'q-custom-elements/src/components/entities/service/accounts/AccountsServicesList'

import STEP_OPERATION from '@/step-operations/accounts-login.json'

export default {
  name: 'AccountsLoginOperation',
  mixins: [
    GettextMixin,
    LoadingElementMixin,
    ApiLoginMixin,
    StepOperationSwiperJsMixin,
    CheckUserExistenceMixin,
    NextUrlMixin,
    BrowserFingerprintMixin,
    FetchBackendStatsMixin,
    ApiServiceSubscribeMixin,
    FetchServicesMixin
  ],
  components: {
    SwiperJs,
    AccountsStepOperationStep,
    AccountsCard,
    AccountsMdcTextField,
    AccountsServicesList
  },
  props: {
    loaded: {
      type: Boolean,
      default: true
    },
    fetchBackendStatsUrl: {
      type: String,
      default: process.env.VUE_APP_DJANGO_SSO_APP_URL + '/api/v1/_stats/'
    },
    checkUserExistenceUrl: {
      type: String,
      default: process.env.VUE_APP_DJANGO_SSO_APP_URL + '/api/v1/auth/check-user/'
    },
	apiLoginUrl: {
      type: String,
      default: process.env.VUE_APP_DJANGO_SSO_APP_URL + '/api/v1/auth/login/'
    },
    fetchServicesUrl: {
      type: String,
      default: process.env.VUE_APP_DJANGO_SSO_APP_URL + '/api/v1/auth/services/'
    }
  },
  data () {
    return {
      operationStarted: false,
      operationCompleted: false,
      operationError: null,
      operationLink: document.href,

      drivenByRoute: false,

      stepOperation: STEP_OPERATION
    }
  },
  computed: {
    backendStats () {
      return this.getStepResult('fetch_backend_stats')
    },

    firstStepError () {
      return this.getStepError('check_credentials') || this.getStepError('run_tasks') || null
    },

    credentialsMessage () {
      return this.gettext('Insert username or e-mail. If you have not created an account yet, then please <a href="/signup/" tabindex="-1">SIGN UP</a> first.')
    },

    userCredentials: {
      get () {
      	let credentials = this.getStepResult('insert-credentials')
        if (credentials) {
          return credentials.trim()
        }
      },
      set (val) {
        this.setStepResult('insert-credentials', val)
      }
    },
    userPassword: {
      get () {
        return this.getStepResult('insert-password')
      },
      set (val) {
        this.setStepResult('insert-password', val)
      }
    },

    services: {
      get () {
        return this.getStepResult('fetch_services')
      }
    },
    selectedService: {
      get () {
        return this.getStepResult('choose-service')
      }
    },
    termsOfService: {
      get () {
        if (this.selectedService) {
          return this.selectedService.tos
        } else {
          return ''
        }
      }
    },

    userLoggedIn () {
      return this.getStepResult('run_tasks') != null
    },

    userProfile () {
      if (this.backendStats && this.backendStats.user) {
        return this.backendStats.user.profile
      } else {
        return null
      }
    }
  },
  methods: {
    clear () {
      this.operationCompleted = false
      this.clearStepOperation()

      this.activateStep('check_browser')
    },
    startOperation () {
      this.operationStarted = true
      this.activateStep('insert-credentials')
    },
    cancelOperation () {
      this.clear()
      this.$emit('operationCancelled')
    },
    completeOperation () {
      this.operationLink = document.location.href

      this.operationCompleted = true
      this.$emit('operationCompleted')
    },
    stopOperation () {
      this.activateStep('insert-credentials')
      this.$emit('operationStopped')
    },
    restartOperation () {
      this.clear()
      this.$emit('operationRestarted')
    },

    // step related

    checkBrowser () {
      if (Modernizr.blobconstructor && Modernizr.svg && Modernizr.promises && Modernizr.filereader) {
        this.stepCompleted('check_browser', true)
      } else {
        this.stepFailed('check_browser', this.gettext('This browser is not supported.'))
      }
    },

    goToProfile () {
      window.location.href = '/profile/update/'
    },

    serviceSelected (service) {
      this.stepCompleted('choose-service', service)
    },

    stepActivated (step) {
      // console.log('step activated', step)
      if (step === 'instructions') {
        this.clearNextSteps()
      }

      if (step === 'check_browser') {
        this.checkBrowser()
      } else if (step === 'fetch_backend_stats') {
        this.fetchBackendStatsP().then(
          response => {
            this.stepCompleted('fetch_backend_stats', response)
          },
          error => {
            this.stepFailed('fetch_backend_stats', error)
          }
        )
      } else if (step === 'fetch_user_object') {
        if (this.backendStats.user) {
          this.$emit('userProfileFetched', this.backendStats.user)
          
          this.stepCompleted(step, true, 'fetch_services')
        } else {
          this.stepCompleted(step, true, 'insert-credentials')
        }
      } else if (step === 'check_credentials') {
        this.checkUserExistenceP(this.userCredentials).then(
          response => {
            this.stepCompleted('check_credentials', response)
          },
          error => {
            this.stepFailed('check_credentials', error, 'insert-credentials')
          })
      } else if (step === 'run_tasks') {
        this.performApiLoginP(this.userCredentials,
                              this.userPassword,
                              this.browserFingerprint).then((response) => {

          this.$emit('userProfileFetched', response.user.profile)

          this.stepCompleted(step, response)
        }, (error) => {
          console.error(error) // Stacktrace
          this.stepFailed(step, error, 'insert-credentials')
        })

      } else if (step === 'passepartout_redirects') {
        if (this.passepartoutRedirectUrl) {
          // console.log('Redirecting redirect_url', this.passepartoutRedirectUrl)
          setTimeout(() => {
            window.location.replace(this.passepartoutRedirectUrl)
          }, 199)
        } else {
          this.stepCompleted(step, false)
        }
      } else if (step === 'check_user') {
        // redirect user to profile complete on first access
        if (this.userLoggedIn) {
          let user = this.getStepResult('run_tasks').user

          if (user.profile.is_incomplete) {
            window.location.href = '/profile/complete/'
            return
          }
        }

        if (this.nextUrl && this.nextUrl.startsWith('/')) {
          // redirects to accounts nextUrl if already logged in
          window.location.href = this.nextUrl
        } else {
          this.stepCompleted(step, true)
        }
      } else if (step === 'fetch_services') {
        if (this.userLoggedIn) {
          // user logged in, fetching services
          this.fetchServicesP().then(
            response => {
              this.stepCompleted(step, response.results)
            },
            error => {
              this.stepFailed(step, error)
            }
          )
        } else {
          this.stepCompleted(step, this.backendStats.services)
        }
      } else if (step === 'choose-service') {
        if (this.nextUrl) {
          // find nextUrl service
          let serviceInNextUrl = false
          for (let i=0; i<this.services.length; i++) {
            let service = this.services[i]

            if (this.nextUrl.indexOf(service.service_url) == 0) { // service_url in nextUrl
              serviceInNextUrl = true
              this.stepCompleted(step, service)
            }
          }

          // clear malformed nextUrl ?
          if (!serviceInNextUrl) {
            // this.nextUrl = null
            this.stepCompleted(step, null, 'send_events')
          }
        }
      } else if (step === 'accept-tos') {
        if (!this.selectedService.subscription_required || this.selectedService.is_subscribed) {
          this.stepCompleted(step, null)
        }
      } else if (step === 'subscribe_service') {
        if (this.selectedService.subscription_required && !this.selectedService.is_subscribed) {
          this.performApiServiceSubscribeP(this.selectedService.subscribe).then(
            response => {
              this.stepCompleted(step, response)
            },
            error => {
              this.stepFailed(step, error)
            }
          )
        } else {
          this.stepCompleted(step, null)
        }
      } else if (step === 'send_events') {
        setTimeout(() => {
            this.stepCompleted(step, true)
        }, 199)
      }  else if (step === 'result') {
        this.completeOperation()

        let redirectWait = 1999

        if (this.selectedService) {
          redirectWait = this.selectedService.redirectWait || 1999
        }

        // where to redirect
        let redirectUrl = this.nextUrl || (this.selectedService && this.selectedService.service_url)

        // redirect to service
        setTimeout(() => {
          console.log('redirecting to', redirectUrl)
          window.location.href = redirectUrl
        }, redirectWait)
      }
    },
    slideChanged (step) {
      if (step === 'insert-credentials') {
        if (this.firstStepError) {
          this.$refs.userCredentialsInput.clear()
        } else {
          this.$refs.userCredentialsInput.focus()
        }
      } else if (step === 'insert-password') {
        if (this.firstStepError) {
          this.$refs.userPasswordInput.clear()
        } else {
          this.$refs.userPasswordInput.focus()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "q-custom-elements/src/scss/accounts.theme";

.accounts-login-operation {
  width: 100%;
  height: 100%;
  color: #fff;

  .instructions {
    text-align: left;
  }

  .tos {
    height: 180px;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 5px;
    background-color: #fff;
    color: #222;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    padding: 16px;
  }
}
</style>
