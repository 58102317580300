import Vue from 'vue'
import axios from 'axios'
import VueGettext from '@paiuolo/pai-vue-gettext/plugins/VueGettext'

import App from './App.vue'
import router from './router'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
Vue.prototype.$http = axios

Vue.use(VueGettext)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
