<template>
  <div class="login-view">

    <AccountsLoginOperation
      ref="operation"

      @userProfileFetched="userProfileFetched"
      @operationCancelled="operationCancelled"
      @operationCompleted="operationCompleted"
    >
    </AccountsLoginOperation>

    <div class="avatar">
      <UserTopBarAvatar
        v-if="showAvatar"
        hide-login-links
        ref="avatar"
        
        logout-url="/api/v1/auth/logout/"
        signup-url="/signup/"

        :external-user-profile="userProfile"
      >

        <li v-if="userProfile == null" class="mdc-list-item static" role="menuitem">
          <a href="/signup/"><h4>{{ gettext('Sign up') }}</h4></a>
        </li>
        <li v-if="userProfile == null" class="mdc-list-item static password-reset-button" role="menuitem">
          <a href="/password/reset/"><h4>{{ gettext('Reset password') }}</h4></a>
        </li>

        <li v-if="userProfile != null" class="mdc-list-item static" role="menuitem">
          <a href="/profile/"><h4>{{ gettext('Profile') }}</h4></a>
        </li>
        <li v-if="userProfile != null" class="mdc-list-item static logout-button" role="menuitem">
          <a href="/logout/"><h4>{{ gettext('Logout') }}</h4></a>
        </li>

      </UserTopBarAvatar>
    </div>

  </div>
</template>

<script>
import GettextMixin from '@paiuolo/pai-vue-gettext/mixins/GettextMixin'
import UserTopBarAvatar from 'q-custom-elements/src/components/entities/user/UserTopBarAvatar'

import AccountsLoginOperation from '../components/step-operations/AccountsLoginOperation'


export default {
  name: 'LoginView',
  mixins: [
    GettextMixin
  ],
  components: {
    AccountsLoginOperation,
    UserTopBarAvatar
  },
  props: {
    showAvatar: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      title: this.gettext('Login'),
      userProfile: null
    }
  },
  mounted () {
    // console.log('ROUTE', this.$route)
  },
  methods: {
    userProfileFetched (p) {
      if (p) {
        this.setLocale(p.language)
        this.userProfile = p
      }
    },
    operationCancelled () {
    },
    operationCompleted () {
    }
  }
}
</script>

<style lang="scss">
@import "q-custom-elements/src/scss/accounts.theme";

.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .password-reset-button {
    background-color: #eee;
  }

  .logout-button {
    background-color: $red;
    a {
      color: #fff;
    }
  }

  .avatar {
    position: fixed;
    top: 0;
    right: 0;
    margin: 1em;

    border: 4px solid #fff;
    border-radius: 999px;
    z-index: 2;

    width: 32px;
    height: 32px;

    @media (min-width: $break-phone){
      width: 48px;
      height: 48px;
    }

    @media ( max-height: 520px ) and (max-width: 420px){
      margin: 8px;
    }
  }
  .avatar:hover {
    box-shadow: 0 0 4px #fff;
  }
}
</style>
